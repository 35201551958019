import { Token } from '@legacyApp/types/wallet/CurrencyType';

export const TokenNames: Record<Token, string> = {
	[Token.usdt]: 'Tether',
	[Token.usdc]: 'USD Coin',
	[Token.busd]: 'Binance USD',
	[Token.btc]: 'Bitcoin',
	[Token.eth]: 'Ethereum',
	[Token.doge]: 'DogeCoin',
	[Token.trx]: 'Tron',
	[Token.xrp]: 'XRP',
	[Token.bnb]: 'Binance Coin',
	[Token.ltc]: 'Litecoin',
	[Token.bch]: 'Bitcoin Cash',
	[Token.ada]: 'Cardano',
	[Token.etc]: 'Ethereum Classic',
	[Token.uni]: 'Unicoin',
	[Token.dot]: 'Polkadot',
	[Token.xlm]: 'Stellar',
	[Token.shib]: 'Shiba Inu',
	[Token.sushi]: 'SushiCoin',
	[Token.pol]: 'Polygon',
	[Token.op]: 'Optimism L2',
	[Token.pepe]: 'Pepe Coin',
	[Token.bonk]: 'Bonk',
	[Token.floki]: 'FLOKI',
	[Token.sol]: 'Solana',
} as const;
