import React, { Fragment } from 'react';

import { parseImageSrc } from '@common/components/default/Image/Image';
import { TokenNames } from '@common/constants/currency/settings/tokenNames';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { pageEnhancer } from '@common/hoc/pageEnhancer';
import { config } from '@legacyApp/client/config';
import { Redirect } from '@common/components/default/Redirect/Redirect';
import { AnyJsonLd, SAME_AS } from '@common/components/seo/AnyJsonLd';
import { currenciesSettings } from '@common/controllers/currency/currenciesSettings';
import routingService from '@legacyApp/client/modules/app/routingService';
import { HomeLayout } from '@modules/home/layouts/Home.layout';
import { getStaticPropsHome } from '@modules/home/methods/getStaticPropsHome';

const pageDescription = () => ({
	options: {
		noParagraphTranslate: true,
		noTitleIndex: true,
		noParagraphIndex: true,
		toggle: true,
		shadowColor: '#0b1b36',
	},
	namespace: 'homePageContent',
	title: {
		message: '{{appName}} The Bitcoin & Crypto Casino',
		values: {
			appName: config.appName,
		},
	},
	content: [
		{
			title: 'question1',
			paragraphs: [
				[
					{
						message: 'answer1-1',
						values: {
							currencies: currenciesSettings.tokens
								.map((token) => TokenNames[token])
								.join(', '),
						},
					},
				],
				['answer1-2'],
				['answer1-3'],
			],
		},
		{
			title: 'question2',
			paragraphs: [
				['answer2-1'],
				[
					{
						message: 'answer2-2',
						components: (
							<Fragment key="provably-fair">
								Our current games only charge a 1% house edge and every bet
								placed on them can be 100% verified through our
								<Redirect
									target="_blank"
									rel="noreferrer"
									className="text-underline"
									href="/provably-fair?tab=overview"
								>
									<>Provably Fair</>
								</Redirect>
								page, assuring players get a fair and transparent experience.
							</Fragment>
						),
					},
				],
			],
		},
		{
			title: 'question3',
			paragraphs: [['answer3-1']],
		},
		{
			title: 'question4',
			paragraphs: [['answer4-1']],
		},
		{
			title: 'question5',
			paragraphs: [['answer5-1']],
		},
		{
			title: 'question6',
			paragraphs: [
				['answer6-1'],
				['answer6-2'],
				['answer6-3'],
				[
					{
						message: 'answer6-4',
						components: (
							<Fragment key="promotions">
								For more information about our promotions, please check our
								<Redirect
									target="_blank"
									rel="noreferrer"
									className="text-underline"
									href="/promotions"
								>
									<>Promotions</>
								</Redirect>
								page.
							</Fragment>
						),
					},
				],
			],
		},
		{
			title: 'question7',
			paragraphs: [
				['answer7-1'],
				['answer7-2'],
				[
					{
						message: 'answer7-3',
						components: (
							<Fragment key="promotions">
								For more information about our VIP system, please visit the
								<Redirect
									target="_blank"
									rel="noreferrer"
									className="text-underline"
									href={routingService.to(ROUTING_ID.USER_VIP)}
								>
									<>VIP</>
								</Redirect>
								page.
							</Fragment>
						),
					},
				],
			],
		},
	],
});

const PAGE_ID = ROUTING_ID.HOME;
const NO_INDEX = false;

export async function getStaticProps(props) {
	return getStaticPropsHome(props, PAGE_ID);
}

export default pageEnhancer(HomeLayout, {
	id: PAGE_ID,
	pageDescription,
	structuredData: (
		<AnyJsonLd
			props={{
				'@type': 'Corporation',
				brand: config.appName,
				logo: parseImageSrc('/img/logo_black.png'),
				contactPoint: {
					'@type': 'ContactPoint',
					email: `support@${config.domain}`,
					contactType: 'customer service',
				},
				sameAs: SAME_AS,
			}}
		/>
	),
	seoProps: {
		noindex: NO_INDEX,
		nofollow: NO_INDEX,
	},
});
